.term {
    h1 {
        font-weight: 700;
        color: #999999;
    }

    padding-top: 70px;

    h6 {
        font-weight: 700;
        font-size: 15px;
    }

    p {
        font-size: 15px;
        line-height: 25px;
    }
}