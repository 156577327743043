//cdn import style
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&display=swap');

//bootstrapt
@import '~bootstrap/dist/css/bootstrap.min.css';
//sass utility for breakpoint
@import '~bootstrap/scss/bootstrap-utilities';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

//variables
//colors
$yellow : #fd0;
$light-grey : #e5e5e59c;
$smoke: #E8E8E8;
$blue : #206AEC;
$green: #41A944;
$red: #E5452D;



// imports 
@import './global.scss';
@import './home.scss';
@import './privacy.scss';
@import './term.scss';
@import './shared.scss';