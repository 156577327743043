.navbar-custom {
    height: 70px;
    font-size: 15px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: white;

    .logo {
        height: 55px;
        object-fit: contain;

        @include media-breakpoint-down(md) {
            height: 35px;

        }
    }

    .links {
        a {
            margin-right: 48px;
            text-transform: uppercase;
            // font-weight: 300;
            color: black;
            transition: .5s;

            &:hover {
                // font-weight: 600;
                transition: .5s;
            }
        }

    }

    .burger {
        position: relative;
        z-index: 200;
    }

    .mob-menu {
        height: 100vh;
        background: white;
        // background: #FF4E50;
        // /* fallback for old browsers */
        // background: -webkit-linear-gradient(to right, #F9D423, #FF4E50);
        // /* Chrome 10-25, Safari 5.1-6 */
        // background: linear-gradient(to right, #F9D423, #FF4E50);
        // /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        z-index: 100;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                text-align: center;
                font-size: 40px;

                a {
                    color: white !important;
                    font-weight: 800;
                    margin-bottom: 16px;

                    &::before {
                        height: 5px;
                    }
                }
            }
        }
    }
}

.layout {
    position: relative;

    .floatwa {
        position: fixed;
        bottom: 35px;
        right: 35px;
        background-color: #25d366;
        border-radius: 100%;
        z-index: 100;
        padding: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    @include media-breakpoint-down(md) {
        .floatwa {
            bottom: 25px;
            right: 25px;
        }
    }
}

.footer {
    font-size: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;


    p {
        max-width: 274px;
        min-width: 160px;
        margin-bottom: 8px;
        line-height: 30px;
        font-weight: 300;
    }

    .logo {
        height: 55px;
        object-fit: contain;
    }

    .links {
        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .social {
        svg {
            margin-right: 16px;
        }
    }

    @include media-breakpoint-down(md) {
        padding-bottom: 60px !important;

        p {
            line-height: 24px;
        }

        .wrapbox {
            gap: 30px;

            .box {
                // border: 1px solid red;
                flex: auto;
                flex-basis: 250px;

                &-link {
                    text-align: right;

                    p {
                        max-width: unset;
                    }
                }

                &-social {
                    text-align: right;

                    p {
                        max-width: unset;
                    }

                    .social {
                        justify-content: end;

                        svg {
                            margin-right: unset;
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 35px !important;

        .logo {
            height: 35px;
        }

        .wrapbox {
            gap: 12px;

            .box {
                // border: 1px solid grey;
                flex: auto;
                flex-basis: 470px;
                text-align: center;
                font-size: 10px;
                margin: 0 auto;

                &-address {
                    justify-content: center;
                    display: flex;
                    line-height: 18px;
                }

                &-link {
                    text-align: center;

                    p {
                        max-width: unset;
                    }
                }

                &-social {
                    text-align: center;

                    p {
                        max-width: unset;
                    }

                    .social {
                        justify-content: center;

                        svg {
                            width: 17px;
                            margin-right: unset;
                            margin-left: unset;
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
}