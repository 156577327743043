* {
    font-family: 'Poppins', sans-serif !important;
    scroll-behavior: smooth;
    // transition: 1s all ease-in-out;

}

body {
    margin: 0 !important;
    padding: 0 !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1112px !important;
}



a {
    text-decoration: unset !important;
    color: black;
}

.pointer {
    cursor: pointer;
}

// custom button
.btn-primary {
    align-items: center;
    background-clip: padding-box;
    background-color: $yellow;
    border: 1px solid transparent;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;

}

.btn-primary:hover,
.btn-primary:focus {
    background-color: $yellow;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.btn-primary:hover {
    transform: translateY(-1px);
}

.btn-primary:active {
    background-color: $yellow;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

.py-main {
    padding-top: 64px;
    padding-bottom: 64px;
}

h1 {
    font-size: 35px !important;
}

h2 {
    font-size: 24px !important;
    font-weight: 700 !important;
}

@include media-breakpoint-down(sm) {
    h1 {
        font-size: 26px !important;
    }

    h2 {
        font-size: 18px !important;
    }
}


b {
    font-weight: 700 !important;
}

.animlink {
    background-image: linear-gradient(to right,
            black,
            black 50%,
            #000 50%);
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 0px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
}

.animlink:before {
    content: '';
    background: $yellow;
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
}

.animlink:hover {
    background-position: 0;
}

.animlink:hover::before {
    width: 100%;
}

.animated-container {
    justify-content: center !important;
}

.slick-slide,
.slick-active {
    outline: none !important;
    border: none !important;
}