.privacy {
    min-height: 100vh;
    padding-top: 70px;
    overflow-y: scroll;
    position: relative;

    h1 {
        font-weight: 700;
        color: #999999;
    }

    .menus {
        position: relative;
        padding-right: 100px;

        ol {
            top: 70px+130px;
            position: fixed;
            color: $blue !important;
            padding: 0;
            // font-size: 14px;
            font-weight: 300;

            li {
                // list-style: none;

                a {

                    p {
                        width: 90%;
                        line-height: 16px;
                        color: $blue !important;
                        text-transform: capitalize;
                    }

                }
            }
        }
    }

    .privcon {
        .contents {

            .offscroll {
                display: block;
                position: relative;
                top: -100px;
                visibility: hidden;
            }

            h6 {
                font-weight: 700;
            }

            .desc {
                font-size: 14px;
            }

        }
    }

}