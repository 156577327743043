@keyframes coverAnim {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(60vw);
  }
}

.home {
  .jumbotron {
    position: relative;
    height: 100vh;

    .bgjumbo {
      position: absolute;
      top: 0px;
      right: 0;
      object-fit: contain;
      padding-top: 70px;
      height: 100%;
    }

    .wrapcon {
      z-index: 1;
      position: relative;
      min-height: 100vh;
      padding-top: 70px;

      h1 {
        font-size: 35px;
        font-weight: bold;
      }

      h5 {
        font-size: 20px;
        font-weight: 300;
        max-width: 453px;
        line-height: 30px;
      }
    }

    @include media-breakpoint-down(md) {
      height: unset;
      min-height: unset;
      border-bottom: 1px solid #c7c7c7;

      .container {
        padding: 0;
      }

      .bgjumbo {
        position: inherit;
        width: 100%;
      }

      .wrapcon {
        min-height: unset;
        padding-top: 14px;
        justify-content: center;

        h1 {
          font-size: 24px;
        }

        h5 {
          font-size: 14px;
          line-height: 20px;
          padding: 0 30px;
        }

        .jumbocon {
          text-align: center;
          padding-bottom: 30px;

          button {
            width: 50%;
          }
        }
      }
    }
  }

  .client {
    h2 {
      font-size: 24px;
      font-weight: 700;
    }

    .desc {
      font-weight: 300;
      font-size: 18px;
    }

    .wraplogos {
      flex-wrap: wrap;
      gap: 10px;

      .figlogo {
        flex: auto;
        flex-basis: 200px;
        margin-top: 50px;
        position: relative;

        img {
          // max-width: 144px;
          object-fit: contain;
          height: 55px;
          aspect-ratio: 4/2;
        }

        .wrapwa {
          // border: 1px solid black;
          position: relative;

          .wame {
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #25d366;
            box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
            z-index: 5;
            width: 30px;
            height: 30px;
            position: absolute;
            cursor: pointer;
            right: -15px;
            bottom: -15px;
          }
        }
      }
    }

    .ukmslideitem {
      padding: 20px 0;

      .ukmlogo {
        height: 55px;
        // max-width: 140px;
        aspect-ratio: 4/2;
        object-fit: contain;
      }

      .wrapwa {
        // border: 1px solid black;
        position: relative;

        .wame {
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #25d366;
          box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
          z-index: 5;
          width: 30px;
          height: 30px;
          position: absolute;
          cursor: pointer;
          right: -15px;
          bottom: -15px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px !important;
      padding-bottom: 30px !important;

      @include media-breakpoint-down(md) {
        .desc {
          font-size: 16px;
        }
      }

      .wraplogos {
        .figlogo {
          flex-basis: 150px;
          margin-top: 30px;

          img {
            // max-width: 110px;
            object-fit: contain;
            height: 60px;
            aspect-ratio: 3/2;
          }
        }
      }

      .ukmslideitem {
        .ukmlogo {
          height: 40px;
          //   max-width: 120px;
          aspect-ratio: 4/2;
        }
      }
    }
  }

  .balesin-section {
    .box {
      border-radius: 50px;
      background-color: $yellow;
      padding: 27px 0;

      .wrapimg {
        // flex: 1;
        width: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: red;
        // flex: 1;
        // height: 600px;

        .phoneimg {
          // opacity: .4;
          object-fit: contain;
          width: 100%;
          position: absolute;
        }
      }

      .wraplist {
        position: relative;
        width: 50%;

        .title {
          font-size: 25px;
        }

        // .logoTitle {
        //     height: 35px;
        // }

        .desc {
          max-width: 420px;
          font-size: 18px;
          font-weight: 300;

          b {
            font-weight: 700;
          }
        }

        ul {
          padding: 0;
          margin: 0;
          max-width: 447px;

          li {
            list-style: none;
            margin-bottom: 13px;
            font-size: 15px;
            font-weight: 300 !important;
          }
        }

        .avail {
          font-size: 12px;
          // font-weight: 300;
        }

        .wrapsoc {
          background: white;
          border-radius: 100%;
          width: 33px;
          height: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .soclogo {
            width: 22px;
            object-fit: contain;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      padding-bottom: 30px;
      background-color: $yellow !important;

      .py-main {
        all: unset;
      }

      .box {
        flex-wrap: wrap;
        border-radius: 20px;
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;

        .wrapimg {
          width: 100%;
          display: block;

          .avail {
            font-size: 8px;
            // font-weight: 300;
          }

          .wrapsoc {
            background: white;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 4px;

            .soclogo {
              width: 14px;
              object-fit: contain;
            }
          }

          .phoneimg {
            padding: 0 16px;
            margin-top: 16px;
            // height: 500px;
            // background-color: red;
            max-width: 100%;
            min-width: 100%;
            position: relative;
          }
        }

        .wraplist {
          // margin-top: 40%;
          width: 100%;

          .title {
            font-size: 18px;
            margin-bottom: 8px;
          }

          .desc {
            max-width: unset;
            font-size: 15px;
          }

          ul {
            li {
              font-size: 12px;
              margin-bottom: 4px !important;
            }

            svg {
              width: 16px;
              margin-right: 4px !important;
            }
          }
        }
      }
    }
  }

  .our360 {
    position: relative;

    // min-height: 70vh;
    .box {
      position: relative;

      h6 {
        font-size: 18px;
        font-weight: 300;
        position: absolute;
      }

      .chat {
        top: 27%;
        left: -39%;
      }

      .market {
        top: 27%;
        right: -24.5%;
      }

      .cus {
        bottom: -8%;
        /* left: 10%; */
        margin: 0 auto;
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      padding-bottom: 50px;

      .box {
        img {
          // background-color: red;
          width: 195px;
        }

        h6 {
          font-size: 10px;
          font-weight: 400;
        }

        .chat {
          top: 24%;
          left: -38%;
          max-width: 70px;
          text-align: right;
        }

        .market {
          top: 27%;
          right: -30.5%;
        }

        .cus {
          bottom: -10%;
          /* left: 10%; */
          margin: 0 auto;
        }
      }
    }
  }

  .features {
    position: relative;

    .wrapfeatcon {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    .featuresbox {
      border-radius: 50px;
      padding: 0px 80px;
      height: 350px;

      // overflow: hidden;
      @include media-breakpoint-down(lg) {
        padding: 0px 16px;
      }

      .wraplist {
        position: relative;

        .logoTitle {
          height: 35px;
        }

        .desc {
          font-size: 20px;
          font-weight: bold;
        }

        ul {
          padding: 0;

          li {
            list-style: none;
            margin-bottom: 13px;
            font-size: 15px;
            font-weight: 300 !important;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .wrapimg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 378px;
        // background-color: red;
        // flex: 1;
        // height: 600px;

        .phoneimg {
          // opacity: .4;
          object-fit: contain;
          // width: 358px;
          // min-width: 357px;
          position: absolute;
        }
      }

      .wrapimg-0 {
        .phoneimg {
          width: 330px;
        }
      }

      .wrapimg-1 {
        .phoneimg {
          width: 300px;
        }
      }

      .wrapimg-2 {
        // background-color: red;
        height: 100%;
        align-items: flex-end;

        .phoneimg {
          position: unset;
          z-index: 1;
          width: 280px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 0px;
      padding-bottom: 0px;

      .wrapfeatcon {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0;
        padding-right: 0;
      }

      .featuresbox {
        height: unset;
        flex-wrap: wrap;
        padding: 30px 16px 0px;
        border-radius: unset;

        .wrapimg {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          ul {
            padding: 0;

            li {
              font-size: 12px;
              margin-bottom: 4px !important;
            }

            svg {
              width: 16px;
              margin-right: 4px !important;
            }
          }

          .phoneimg {
            max-width: 212px;
            // height: 500px;
            // background-color: red;
            // max-width: 50%;
            // top: -150px;
            position: relative;

            &-0 {
              top: unset;
            }

            &-1 {
              top: unset;
            }

            &-2 {
              bottom: unset;
            }
          }
        }

        .wrapimg-2 {
          flex-direction: column-reverse;
          overflow: hidden;
        }

        .wraplist {
          // margin-top: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          .logoTitle {
            height: 21px;
            margin-bottom: 8px !important;
          }

          .desc {
            max-width: unset;
            font-size: 15px;
            text-align: center;
            max-width: 248px;
          }

          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .ourstory {
    h2 {
      margin-bottom: 40px;
    }

    .wrapicon {
      width: 100px;
      height: 100px;
      background: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      box-shadow: 5px 5px 5px #03050433;
    }

    .name {
      height: 26px;
    }

    .box {
      height: 150px;

      .value {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .desc {
        font-size: 18px;
        max-width: 247px;
        font-weight: 300;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 0px;

      h2 {
        margin-bottom: 15px;
      }

      .name {
        height: 22px;
      }

      .wrapicon {
        width: 60px;
        height: 60px;

        img {
          width: 30px;
        }
      }

      .box {
        height: 100px;
        margin-bottom: 18px;

        .value {
          font-size: 40px;
          font-weight: 700;
        }

        .desc {
          max-width: 200px;
          font-size: 14px;
        }
      }
    }
  }

  .reachus {
    background-color: $yellow;
    position: relative;
    overflow: hidden;
    height: 325px;

    .wrapcon {
      position: relative;

      .rocket {
        object-fit: contain;
        height: 90%;
        position: absolute;
        left: 0%;
        bottom: 0;
        z-index: 0;
      }

      h2 {
        position: relative;
        font-size: 24px;
        max-width: 613px;
        font-weight: 700;
        margin-bottom: 26px;
        z-index: 1;
        line-height: 40px;
      }
    }

    @include media-breakpoint-down(sm) {
      height: unset;

      .wrapcon {
        margin: 0 auto;

        h2 {
          font-size: 15px !important;
          margin-top: 25px;
          line-height: 20px;
        }

        button {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          width: 70%;
          margin-bottom: 45px;
        }

        .rocket {
          position: inherit;
          width: 150px;
        }
      }
    }
  }

  .solution-partner {
    h2 {
      font-weight: 700;
    }

    .desc {
      font-size: 18px;
      font-weight: 300;
    }

    .wraplogos {
      flex-wrap: wrap;
      gap: 10px;

      .figlogo {
        flex: auto;
        flex-basis: 200px;
        margin-top: 50px;

        img {
          //   max-width: 144px;
          object-fit: contain;
          height: 50px;
          aspect-ratio: 6/2;
          //   background: red;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px !important;
      padding-bottom: 30px !important;

      .desc {
        margin-bottom: 32px;
        font-size: 12px;
        max-width: 214px;
        margin: 0 auto;
      }

      .wraplogos {
        .figlogo {
          flex-basis: 150px;
          margin-top: 30px;

          img {
            object-fit: contain;
            height: 40px;
            aspect-ratio: 6/2;
          }
        }
      }
    }
  }

  .media-coverage {
    @include media-breakpoint-down(md) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .medialogo {
      height: 32px;
      aspect-ratio: 9/2;
      //   height: 25px;
      //   max-width: 150px;
      object-fit: contain;
      filter: grayscale(1);
      transition: all 0.3s;
      opacity: 0.5;

      &:hover {
        filter: grayscale(0);
        opacity: 1;
        transform: scale(1.2);
        z-index: 5;
        position: relative;
      }
    }
  }
}
